import React, { Component }  from 'react';
import './App.css';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home"
import About from "./pages/About"
import Advantages from "./pages/Advantages"
import { HelmetProvider } from 'react-helmet-async';
import Braces from "./pages/Braces"
import Feedback from "./pages/Feedback"
import Rules from "./pages/Rules"
import Aligners from "./pages/Aligners"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Helmet } from 'react-helmet-async';

function App() {

  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
    <Navbar></Navbar>
      <Router>
      <Helmet>
        <title>Dr. Oleksandr Kostenko / Оротодонт Олександр Костенко</title>
        <meta
          name="description"
          content="Оротодонт Олександр Костенко Ужгород"
        />
        <meta
          name="keywords"
          content="Ортодонт, ортодонт Ужгород, Ужгород ортодонт, Ортодонт Ужгород, ортодонт ужгород, Ужгород, Костенко, ортодонт, ужгород, костенко, брекети, Брекети, стоматолог, Стоматолог, стоматологія, Стоматологія, uzhhorod, Uzhhorod, orthodont, зуби, Зуби"
        />
      </Helmet>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/advantages" exact element={<Advantages />} />
          <Route path="/braces" exact element={<Braces />} />
          <Route path="/feedback" exact element={<Feedback />} />
          <Route path="/rules" exact element={<Rules />} />
          <Route path="/aligners" exact element={<Aligners />} />
        </Routes>
      </Router>

      <Footer></Footer>
      </HelmetProvider>
  
  );
}
  
export default App;