import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from 'react-helmet-async';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const Advantages = () => (
    <div id='cont' className='cont' style={{marginBottom:'-60px', paddingBottom:'40px'}}>
        <Helmet>
        <title>Dr. Oleksandr Kostenko / Оротодонт Олександр Костенко</title>
        <meta
          name="description"
          content="Оротодонт Олександр Костенко Ужгород"
        />
        <meta
          name="keywords"
          content="Ортодонт, ортодонт Ужгород, Ужгород ортодонт, Ортодонт Ужгород, ортодонт ужгород, Ужгород, Костенко, ортодонт, ужгород, костенко, брекети, Брекети, стоматолог, Стоматолог, стоматологія, Стоматологія, uzhhorod, Uzhhorod, orthodont, зуби, Зуби"
        />
      </Helmet>
    <div>НАШІ ПЕРЕВАГИ</div>
    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/digital.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>ЦИФРОВА НЕПРЯМА ФІКСАЦІЯ. </div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'>Встановлення системи на комп’ютері за сканами щелеп пацієнта. Друкуємо моделі на 3D-принтері та створюємо спеціальні переносні капи. Це набагато точніше та сучасніше, адже скани поєднуються із комп`ютерною томографією</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img style={{width: '300px'}} className='advantages' src="../images/Group 3.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>СПЕЦІАЛЬНІ ШИРОКІ ДУГИ </div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'>Pitts Broad, що дозволяють створити широку посмішку за допомогою розширення зубних рядів.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>

    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/nakladka.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>НАКЛАДКИ</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> для роз’єднання прикусу та міорелаксації для швидшої роботи системи через виключення заклинювання «зубів об зуби». Через них деякий час зуби змикатися НЕ БУДУТЬ!</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img style={{width: '300px'}} className='advantages' src="../images/screen4.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>ІНДИВІДУАЛЬНЕ РОЗТАШУВАННЯ БРЕКЕТІВ.</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> Модифіковане позиціонування брекетів на зубі, яке ґрунтується не на табличних значеннях, а на індивідуальних параметрах кожного пацієнта, що дозволяє створити так звану «дугу посмішки» (smile arch), що є естетичним еталоном сучасної, привабливої та молодої посмішки.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/impl.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>МІКРО-ІМПЛАНТИ</div>
    <div style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> — маленькі шурупчики, які вкручуються між коренями зубів; абсолютна опора для тяг, яка дозволяє вирішити навіть найскладніші випадки із значним переміщенням зубів.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/Group3.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>СЕПАРАЦІЇ АБО IPR (інтерпроксимальна редукція)</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> — безболісна нешкідлива неприємна процедура створення малесеньких проміжків між зубами за рахунок зменшення обсягу емалі для створення місця без видалень.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/elastics.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>ЕЛАСТИКИ</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> для зміни прикусу і створення щільних міжзубних контактів.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage' >
    <div className='advantageImg'> <img className='advantages' src="../images/screen8.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>Одразу ДВА види РЕТЕНЦІЇ.</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> Закріплення результату одночасно як незнімним ретейнером, так і спеціальними капами.</div>
          </div>
  
    </div>

    </div>
);

export default Advantages;