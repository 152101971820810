import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from 'react-helmet-async';

const Rules = () => (
    <div  id='cont' className='cont1'>
        <Helmet>
        <title>Dr. Oleksandr Kostenko / Оротодонт Олександр Костенко</title>
        <meta
          name="description"
          content="Оротодонт Олександр Костенко Ужгород"
        />
        <meta
          name="keywords"
          content="Ортодонт, ортодонт Ужгород, Ужгород ортодонт, Ортодонт Ужгород, ортодонт ужгород, Ужгород, Костенко, ортодонт, ужгород, костенко, брекети, Брекети, стоматолог, Стоматолог, стоматологія, Стоматологія, uzhhorod, Uzhhorod, orthodont, зуби, Зуби"
        />
      </Helmet>
      <div style={{textAlign: "center"}}>ЕЛАЙНЕРИ</div>
      <div style={{textAlign: "center", marginBottom:'60px', marginTop:'40px'}}> <img className='braces' src="../images/ali.webp" alt=""></img></div>
      


    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/12.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>ЕЛАЙНЕРИ </div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'>- це прозорі еластичні ортодонтичні капи, які виготовляються індивідуально на замовлення із полікарбонату на кожну щелепу.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img style={{width: '300px'}} className='advantages' src="../images/13.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>БОКС</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> – це пластикова коробочка для зберігання елайнерів під час вживання їжі та напоїв та їхнього транспортування.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>

    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/14.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>ГАЧОК</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> – це пластиковий інструмент для полегшення зняття елайнера. Краще застосовувати коло задніх зубів.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img style={{width: '300px'}} className='advantages' src="../images/15.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>МОНОПУЧКОВА ЩІТКА</div>
    <div  style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> – це предмет гігієни, яким найлегше і найкраще чистити елайнери.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>


    <div className='advantage'>
    <div className='advantageImg'> <img className='advantages' src="../images/16.webp" alt=""></img></div>
    <div style={{width: '80%', height: '100%'}}>
    <div className='bracesName laka'  style={{textAlign: "center", display: 'inline'}}>Атачменти</div>
    <div style={{textAlign: "center", display: 'inline'}} className='bracesDesc'> – це маленькі пломбочки, схожі на горбики. У кожного пацієнта завжди різна кількість атачментів, це залежить від складності випадку. Вони виготовляються із пломбувального матеріалу в колір зуба та в основному розташовуються на боковій групі зубів і помітити їх вкрай важко. За рахунок атачментів здійснюються повороти та переміщення зубів, а також фіксація елайнерів.</div>
          </div>
  
    </div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>

    <div style={{textAlign: "center"}}>РЕКОМЕНДАЦІЇ ДЛЯ ПАЦІЄНТІВ З ЕЛАЙНЕРАМИ</div>
    <div className='carousel kkka' style={{textAlign: "left"}}>
    <div>🚀 <span className='highl'>Звикання до елайнерів. </span> У перші дні ви матимете дискомфорт та порушення мови. Але чим більше ви говоритимете, чим більше артикулюватимете, тим швидше «навчитеся» знову чітко і виразно говорити вже в елайнерах. Також в перші дні можуть з’явитися незначні больові відчуття.</div>
    <div>🚀<span className='highl'>Бути в елайнерах 22/7. </span>Дві години на їду, каву та чищення зубів. Спати теж в елайнерах.</div>
    <div>🚀 <span className='highl'>ГІГІЄНА ротової порожнини. </span> Чистіть ваші зуби як зазвичай, двічі на день. Рекомендована професійна гігієна кожні 4 місяці.</div>
    <div>🚀 <span className='highl'>Чищення елайнерів: </span> обережно почистити теплою водою з pH-нейтральним милом. Не забувайте робити це щоразу, коли ви хочете одягнути капу після їжі, інакше на ній почнуть накопичуватися залишки їжі та запахи.</div>
<div>🚀 <span className='highl'>Замінюйте </span> ваші елайнери на наступні кожні 10 днів.</div>
<div>🚀 <span className='highl'> Приходити на прийом </span>кожні 8-10 тижнів (залежно від кількості кап, плану, проживання та етапу лікування).</div>
 <div>🚀 <span className='highl'>ХАРЧУВАННЯ.</span> Вживайте їжу як зазвичай! Для елайнерів не важливі харчові звички та вподобання. Лише не забудьте ЇХ ЗНЯТИ перед їдою чи вживанням кольорових напоїв (кава, чай тощо). Для легшого знімання скористайтесь спеціальним гачком</div>
 <div>🚀 Під час їди або необхідного транспортування складайте їх у спеціальний бокс. Мийте бокс всередині кожні 10 днів..</div>
 <div>🚀 <span className='highl'>У разі відклеювання </span> атачмента терміново напишіть лікарю про це та запишіться на його відновлення.</div>
<div>🚀 Зуби будуть <span className='highl'>рухатися </span>- це<span className='highl'> нормально. </span></div>

 <div>🚀 <span className='highl'>БУТИ НА ЗВ’ЯЗКУ ЗІ СВОЇМ ОРТОДОНТОМ.</span></div>
</div>
</div>

);

export default Rules;


