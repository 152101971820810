import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from 'react-helmet-async';

const Braces = () => (
    <div  id='cont' className='cont' style={{marginBottom:'-60px', paddingBottom:'40px'}}>
        <Helmet>
        <title>Dr. Oleksandr Kostenko / Оротодонт Олександр Костенко</title>
        <meta
          name="description"
          content="Оротодонт Олександр Костенко Ужгород"
        />
        <meta
          name="keywords"
          content="Ортодонт, ортодонт Ужгород, Ужгород ортодонт, Ортодонт Ужгород, ортодонт ужгород, Ужгород, Костенко, ортодонт, ужгород, костенко, брекети, Брекети, стоматолог, Стоматолог, стоматологія, Стоматологія, uzhhorod, Uzhhorod, orthodont, зуби, Зуби"
        />
      </Helmet>
    <div>ВИДИ БРЕКЕТІВ, ЯКІ МИ ПРОПОНУЄМО</div>
    <div className='bracesC' >
    <div> <img className='braces' src="../images/br1.webp" alt=""></img></div>
    <div className='bracesName'  style={{textAlign: "center"}}>МЕТАЛЕВІ САМОЛІГАТУРНІ AMERICAN ORTHODONTICS Empower 2 (США)</div>
    <div className='bracesDesc'>одні з найкращих на ринку, чудова якість та швидке лікування</div>
    <div className='lineeC'><img className='linee' src="../images/Rectangle 21.png"
          alt=""></img></div>
    </div>

    <div className='bracesC'>
    <div> <img className='braces' src="../images/br2.webp" alt=""></img></div>
    <div className='bracesName'  style={{textAlign: "center"}}>РОДІЄВІ САМОЛІГАТУРНІ GC Experience (Японія)</div>
    <div className='bracesDesc'>ідентичні з попередніми, але із світлим родієвим напиленням</div>
</div>

    </div>
);

export default Braces;