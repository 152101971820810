import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from 'react-helmet-async';

const Footer = () => {
    return (

        <div  id="footer">
              <Helmet>
        <title>Dr. Oleksandr Kostenko / Оротодонт Олександр Костенко</title>
        <meta
          name="description"
          content="Оротодонт Олександр Костенко Ужгород"
        />
        <meta
          name="keywords"
          content="Ортодонт, ортодонт Ужгород, Ужгород ортодонт, Ортодонт Ужгород, ортодонт ужгород, Ужгород, Костенко, ортодонт, ужгород, костенко, брекети, Брекети, стоматолог, Стоматолог, стоматологія, Стоматологія, uzhhorod, Uzhhorod, orthodont, зуби, Зуби"
        />
      </Helmet>
        <div id='foot1'>вулиця Минайська, 35А, Ужгород, Закарпатська область, 88000</div>
        <div id="foot2"><a style={{backgroundColor: '#77ABA5'}} href="https://www.instagram.com/dr.oleksandr_kostenko/"><img style={{width:"40px"}} src="../images/Instagram.png" alt=""></img></a>
          <a style={{textDecoration: 'none'}} href="https://www.facebook.com/dr.oleksandr.kostenko/"><img style={{width:"40px"}} src="../images/Facebook_white.png" alt=""></img></a>
          <a style={{textDecoration: 'none'}} href="tel:0935247313"><img style={{width:"40px"}} src="../images/tel.png" alt=""></img></a>
        </div>
        
        </div>
    
        )
    }
    export default Footer