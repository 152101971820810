import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Helmet } from 'react-helmet-async';

const Rules = () => (
    <div  id='cont' className='cont1'>
        <Helmet>
        <title>Dr. Oleksandr Kostenko / Оротодонт Олександр Костенко</title>
        <meta
          name="description"
          content="Оротодонт Олександр Костенко Ужгород"
        />
        <meta
          name="keywords"
          content="Ортодонт, ортодонт Ужгород, Ужгород ортодонт, Ортодонт Ужгород, ортодонт ужгород, Ужгород, Костенко, ортодонт, ужгород, костенко, брекети, Брекети, стоматолог, Стоматолог, стоматологія, Стоматологія, uzhhorod, Uzhhorod, orthodont, зуби, Зуби"
        />
      </Helmet>
    <div style={{textAlign: "center"}}>БАЗОВІ ПРАВИЛА ДЛЯ ОРТОДОНТИЧНИХ ПАЦІЄНТІВ</div>
    <div className='carousel kkka' style={{textAlign: "left"}}>
    <div>🚀 <span className='highl'>ОБОВ’ЯЗКОВЕ ДОТРИМАННЯ РЕКОМЕНДАЦІЙ ЛІКАРЯ.</span> Носіння еластиків, вправи тощо.</div>
    <div>🚀<span className='highl'>ЗВИКАННЯ ДО БРЕКЕТ-СИСТЕМИ. </span>У перші дні після фіксації брекетів (в середньому близько 3-7 днів), можуть виникати деякий дискомфорт та больові відчуття. При виражених больових відчуттях можна використовувати будь-які знеболюючі препарати, які вам зазвичай допомагають при зубному або головному болю.</div>
    <div>🚀 <span className='highl'>БРЕКЕТИ</span> можуть натирати щоки, язик та губи.</div>
    <div>🚀 <span className='highl'>ГІГІЄНА.</span> Важливість правильної гігієни порожнини рота при ортодонтичному лікуванні.
навколо брекетів збирається зубний наліт, який містить мільйони шкідливих мікроорганізмів. Вони виділяють агресивні речовини. Якщо не вичищати, вони поступово роз'їдають емаль зубів, на ній можуть утворюватися білі плями (ділянки демінералізації), особливо помітні після зняття брекетів;</div>
<div>•  <span className='highl'>ЯКЩО НЕ ВИЧИЩАТИ,</span> виникає запалення ясен (гінгівіт), вони стають набряклими, починають боліти і кровоточити;</div>
<div>• внаслідок <span className='highl'> ПОГАНОЇ ГІГІЄНИ </span>починається запалення ясен і зуби починають переміщуватися повільніше, збільшується термін лікування, брекети частіше відпадають;</div>
<div>• при постійно поганій гігієні порожнини рота може бути поставлено питання про дострокове припинення ортодонтичного лікування.</div>
<div>•Приходити на прийом кожні 4-8 тижнів (залежно від типу системи, проживання та етапу лікування).</div>
 <div>🚀 <span className='highl'>ХАРЧУВАННЯ.</span> Бути обережним. Подрібнюйте їжу. Обмежити вживання вуглеводних (мучних) продуктів, бо вони тяжко вичищаються та є дуже поживними для мікробів. Обмежити вживання грубої їжі, хрусткої, печива для запобігання відколюванню брекетів; жувальних гумок для запобігання налипання. Відучитися гризти нігті, олівці, ручки.  <span className='highl'>ВСЕ РІЗАТИ І НІЧОГО НЕ ВІДКУШУВАТИ!</span></div>
 <div>🚀 <span className='highl'>БУТИ НА ЗВ’ЯЗКУ ЗІ СВОЇМ ОРТОДОНТОМ.</span></div>
</div>
</div>

);

export default Rules;


